<template>
	<w-layout v-if="!loading" column fill-height my-3>
		<w-flex shrink>
			<w-layout justify-end>
				<w-btn flat icon="add" @click="showCreation = true">
					{{ $t('quickfolders.actions.create') }}
				</w-btn>

				<v-btn color="primary" flat icon @click="showSettings = true">
					<v-icon>settings</v-icon>
				</v-btn>
			</w-layout>
		</w-flex>
		<w-flex align-center justify-center scroll-y>
			<QuickFoldersList :quickfolders="quickfolders" :document-rights="documentRights" :callback="callback" :origin="'quickfolder'" />
		</w-flex>
		<QuickFoldersCreation v-if="showCreation" :company="company" @add="addQuickFolder($event)" @close="showCreation = false" />
		<QuickFoldersSettings
			v-if="showSettings"
			:company="company"
			:quickfolders="quickfolders"
			@remove="removeQuickFolder($event)"
			@replace="replaceQuickFolder($event)"
			@close="showSettings = false"
		/>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'QuickFolders',
	components: {
		QuickFoldersCreation: () => ({
			component: import('@/components/QuickFolders/QuickFoldersCreation')
		}),
		QuickFoldersSettings: () => ({
			component: import('@/components/QuickFolders/QuickFoldersSettings')
		}),
		QuickFoldersList: () => ({
			component: import('@/components/QuickFolders/QuickFoldersList')
		})
	},
	mixins: [QuickFoldersModuleGuard],
	data: function () {
		return {
			loading: false,
			showCreation: false,
			showSettings: false,
			quickfolderRights: false,
			documentRights: false,
			quickfolders: []
		}
	},
	computed: {
		...mapState({
			company: state => state.company.selected
		})
	},
	watch: {
		company: function () {
			this.init()
		}
	},
	created () {
		this.init()
	},
	methods: {
		/**
		 * @param {object} quickfolder
		 *
		 * @return {Promise<void>}
		 */
		addQuickFolder: function (quickfolder) {
			return new Promise(resolve => {
				this.quickfolders.push(quickfolder)
				resolve()
			})
		},
		init: function () {
			this.loading = true
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			if (this.company?.id) {
				this.service.reset().then(() => {
					this.service
						.get(this.company.id)
						.then(res => {
							this.quickfolderRights = res.quickfolder_rights
							this.documentRights = res.document_rights
							this.quickfolders = res.quickfolders.map(item => {
								item.path = item.path + item.folder_name + '/'
								return item
							})
						})
						.finally(() => {
							this.loading = false
							this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
						})
				})
			}
		},
		removeQuickFolder: function (quickfolderId) {
			this.quickfolders = this.quickfolders.filter(o => o.id != quickfolderId)
		},
		replaceQuickFolder: function (quickfolder) {
			this.quickfolders = this.quickfolders.map(o => (o.id == quickfolder.id ? quickfolder : o))
		},
		callback: function () {
			this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('documents.messages.document_created'))
		}
	}
}
</script>
